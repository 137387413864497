import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faAward,
	faBagShopping,
	faBan,
	faCheck,
	faChevronLeft,
	faChevronRight,
	faCircleCheck,
	faCircleExclamation,
	faClock,
	faCreditCard,
	faExternalLink,
	faEye,
	faGavel,
	faHeart,
	faHouse,
	faLayerGroup,
	faPaperPlane,
	faPiggyBank,
	faShoppingBag,
	faSpinnerThird,
} from '@awesome.me/kit-428607b2fd/icons/classic/regular'

library.add(
	faAward,
	faBagShopping,
	faBan,
	faCheck,
	faChevronLeft,
	faChevronRight,
	faCircleCheck,
	faCircleExclamation,
	faClock,
	faCreditCard,
	faExternalLink,
	faEye,
	faGavel,
	faHeart,
	faHouse,
	faLayerGroup,
	faPaperPlane,
	faPiggyBank,
	faShoppingBag,
	faSpinnerThird
)
