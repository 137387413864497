import { defineStore } from 'pinia'
import api from '@/util/api'
import { Asset } from '@/types/Asset'
import { useAuthStore } from './AuthStore'
import { Ref, computed, ref } from 'vue'
import { Notice } from '@/types/Notice'
import { BatchClaimStatusResponse, BatchDripClaimResponse } from '@/types/Drip'
import { AssetFilter, Offer } from '@/types/Storefront'
import { useStorefrontStore } from './StorefrontStore'

const ASSET_POLL_TIMEOUT = 60 * 1000
const NOTICE_POLL_TIMEOUT = 60 * 1000

export interface PaginationMeta {
	current_page: number
	last_page: number
	per_page: number
	path?: string
	from?: number
	to?: number
	total?: number
}

export const useAccountStore = defineStore('account', () => {
	const authStore = useAuthStore()
	const storeStore = useStorefrontStore()

	let assetMonInterval
	let noticeMonInterval

	const accountNotices = ref<Notice[]>([])

	function setup() {
		authStore.onEvent('login', () => {
			// console.log('caught login event');
			startUserNoticeMonitor(NOTICE_POLL_TIMEOUT)
		})
		if (userLoggedIn()) {
			// console.log('user is logged in, start monitoring assets');
			startUserNoticeMonitor(NOTICE_POLL_TIMEOUT)
		} else {
			// console.log('user is not logged in');
		}

		return {
			// state
			assets,
			accountNotices,
			offers,
			assetPagination,
			storefrontIds,
			isWalletVersionOutdated,

			// actions
			load,
			getAsset,
			storeActivityAccount,
			loadActiveAuctions,
			dismissNotice,
			loadDrips,
			claimDrips,
			batchStatus,
			loadOffers,
			startUserAssetMonitor,
			stopUserAssetMonitor,

			// getters
			assetsById,
		}
	}

	// state
	const assets = ref<Asset[]>([] as Asset[])
	const offers = ref<Offer[]>([] as Offer[])
	const assetPagination = ref<PaginationMeta>({
		current_page: 1,
		last_page: 1,
		per_page: 100,
	})
	const assetFilters = ref<AssetFilter>({ query: '', filters: [], sort: '' })
	const collectionFilters = ref<string[]>([])
	const storefrontIds = ref<string[]>([])
	const monitoredOptions = ref<{
		page: number
		perPage: number
		filters: AssetFilter
		collections: string[]
	} | null>(null)

	const currentWalletVersion = ref<string>('')
	const polledWalletVersion = ref<string>('')

	// actions
	async function load(
		forceReload: boolean = false,
		options?: { page?: number; filters?: AssetFilter; collectionIds?: string[] }
	): Promise<Ref<Asset[]>> {
		const {
			page = assetPagination.value.current_page,
			filters = { query: '', filters: storeStore.state.activeFilters, sort: '' },
			collectionIds = collectionFilters.value,
		} = options ?? {}

		assetFilters.value = filters
		collectionFilters.value = collectionIds

		const { data, meta, userOwnedStorefrontIds } = await api.loadUserAssets(
			page,
			assetPagination.value.per_page,
			assetFilters.value,
			collectionFilters.value,
			currentWalletVersion.value
		)
		if (forceReload || 0 == assets.value.length) {
			assets.value = data
		}

		assetPagination.value = meta
		storefrontIds.value = userOwnedStorefrontIds

		monitoredOptions.value = {
			page,
			perPage: assetPagination.value.per_page,
			filters: assetFilters.value,
			collections: collectionFilters.value,
		}

		return assets
	}

	async function loadNotices(forceReload: boolean = false): Promise<Ref<Notice[]>> {
		if (forceReload || 0 == accountNotices.value.length) {
			accountNotices.value = await api.loadUserNotices()
		}

		return accountNotices
	}

	async function dismissNotice(notice: Notice): Promise<Ref<boolean>> {
		return await api.dismissUserNotice(notice.id).then((response: boolean): boolean => {
			loadNotices(true)
			return response
		})
	}

	async function loadActiveAuctions(page: number = 1): Promise<{ data: Object; meta: Object }> {
		return await api.loadUserAuctions(page)
	}

	function getAsset(assetId: string) {
		return assetsById.value[assetId] ?? null
	}

	// getters
	const assetsById = computed(() => {
		return assets.value.reduce((all, a) => {
			all[a.asset_identifier] = a
			return all
		}, {})
	})

	function userLoggedIn() {
		return authStore.authenticated
	}

	async function pollAssets() {
		// console.log('polling for assets');,
		const res = await loadWalletVersion()

		if (res.success) {
			if (currentWalletVersion.value === '') {
				currentWalletVersion.value = res.data.walletVersion
			}
			polledWalletVersion.value = res.data.walletVersion
		}

		// console.warn(polledWalletVersion.value, polledWalletVersion.value === currentWalletVersion.value)
	}

	async function pollNotices() {
		return await loadNotices(true)
	}

	async function storeActivityAccount(page: number = 1, perPage: number = 25) {
		return await api.loadStorefrontActivityAccount(page, perPage)
	}

	function startUserAssetMonitor(timeout: number = ASSET_POLL_TIMEOUT) {
		if (assetMonInterval) {
			return
		}
		// console.log('starting asset monitor');

		assetMonInterval = setInterval(pollAssets, timeout)
		pollAssets()
	}

	function stopUserAssetMonitor() {
		clearInterval(assetMonInterval)
		assetMonInterval = null
		currentWalletVersion.value = ''
		polledWalletVersion.value = ''
	}

	function startUserNoticeMonitor(timeout: number) {
		if (noticeMonInterval) {
			return
		}

		noticeMonInterval = setInterval(pollNotices, timeout)
		pollNotices()
	}

	const loadDrips = async () => {
		return await api.loadAccountDrips()
	}

	const claimDrips = async (dripIds: string[]): Promise<BatchDripClaimResponse> => {
		return await api.claimDrips(dripIds)
	}

	const batchStatus = async (batchId: string): Promise<BatchClaimStatusResponse | null> => {
		return await api.batchStatus(batchId)
	}

	async function loadOffers(forceReload: boolean = false): Promise<Ref<Offer[]>> {
		if (forceReload || 0 == offers.value.length) {
			offers.value = await api.getUserOffers()
		}
		return offers
	}

	async function loadWalletVersion() {
		return await api.getLatestWalletVersion()
	}

	const isWalletVersionOutdated = computed(() => {
		return polledWalletVersion.value !== currentWalletVersion.value
	})

	// init
	return setup()
})
