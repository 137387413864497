import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faCashRegister,
	faCalendarXmark,
	faGavel,
	faGlobe,
	faHand,
	faShop,
	faStore,
	faPaperPlane,
} from '@awesome.me/kit-428607b2fd/icons/classic/light'

library.add(faCashRegister, faCalendarXmark, faGavel, faGlobe, faHand, faShop, faStore, faPaperPlane)
