<template>
	<div
		:key="`${drop.season}-${drop.sport}-${drop.drop ?? 0}`"
		class="rounded-lg grid md:grid-cols-[3fr,4fr,2fr] gap-x-4 bg-white border border-black/10"
	>
		<div
			class="aspect-[4/3] lg:aspect-square bg-gradient-to-tr from-gray-200 to-gray-300 rounded-t-lg lg:rounded-l-lg lg:rounded-r overflow-hidden shadow-xl"
		>
			<div
				:style="{ 'background-image': `url(/image/drops/${drop.image})` }"
				:title="drop.name"
				class="size-full bg-cover bg-center"
				v-if="drop.id"
			></div>
			<div v-else class="size-full grid place-content-center uppercase text-gray-500">Coming soon</div>
		</div>

		<div class="px-4 lg:px-0 py-4 overflow-x-hidden">
			<div class="font-poppins">
				<span class="inline-block text-2xl/10 lg:text-3xl/12 align-middle mr-2"
					>{{ drop.sport }} {{ drop.season }}</span
				>
				<span
					v-if="drop.drop ?? false"
					class="inline-block bg-gray-200 text-gray-700 text-sm font-medium align-middle py-2 px-3 rounded-full uppercase"
					>Drop {{ drop.drop }}</span
				>
			</div>
			<div>
				{{ isAfter(new Date(), new Date(drop.openable_at)) ? 'Released' : 'Releases' }}
				{{ drop.openable_at ? format(new Date(drop.openable_at), 'MMMM do, yyyy') : 'TBD' }}
			</div>

			<div class="mt-2 flex flex-wrap gap-2 lg:gap-4 select-none">
				<div v-if="drop.heroes" class="text-center p-4 rounded-lg border border-gray-100">
					<div class="text-xl lg:text-2xl font-medium text-gray-800">
						{{ drop.heroes.toLocaleString() }}
					</div>
					<div class="text-xs uppercase text-gray-600">Heroes</div>
				</div>

				<div v-if="drop.card_count" class="text-center p-4 rounded-lg border border-gray-100">
					<div class="text-xl lg:text-2xl font-medium text-gray-800">
						{{ drop.card_count.toLocaleString() }}
					</div>
					<div class="text-xs uppercase text-gray-600">Cards</div>
				</div>

				<div
					v-if="!drop.soldOut && drop.cards?.total && drop.cards?.revealed"
					class="text-center p-4 rounded-lg border border-gray-100"
				>
					<div class="text-xl lg:text-2xl font-medium text-gray-800">{{ drop.cards.revealed }}%</div>
					<div class="text-xs uppercase text-gray-600">Ripped</div>
				</div>
			</div>

			<div class="mt-2 overflow-x-hidden" v-if="allRares.length">
				<div class="text-sm uppercase font-bold">Still In Packs</div>
				<div class="h-24 overflow-hidden relative">
					<transition-group
						tag="div"
						v-for="(card, i) in showRares"
						:key="card.asset_id"
						:css="false"
						class="absolute top-0 bottom-0 block rounded aspect-[3/4] bg-gray-200 transition-all duration-500 h-24"
						:style="{
							left: (i - 1) * 5 + 'rem',
						}"
					>
						<a
							:href="'/store/' + drop.slug + '/' + card.asset_id.split(':')[2]"
							:title="card.name"
							:key="card.asset_id"
						>
							<img
								:key="card.asset_id"
								class="block rounded bg-cover h-full w-full"
								:src="$cdnify(card.image, 256, 256)"
								:alt="card.name"
							/>
						</a>
					</transition-group>
				</div>
			</div>
		</div>

		<div class="px-4 lg:pl-0 py-4 space-y-2 self-center">
			<div
				v-if="drop.soldOut"
				class="block w-full bg-gray-200 text-gray-400 uppercase font-bold text-center py-2 px-4 rounded-md"
			>
				Sold Out
			</div>

			<a
				v-if="drop.sale"
				:href="drop.sale"
				class="block w-full bg-sky-600 hover:saturate-150 border border-sky-800 text-white uppercase font-bold text-center py-2 px-4 rounded-md hover:scale-105 shadow-lg transition-all"
				>Buy Packs</a
			>

			<a
				v-if="drop.setBuilder"
				:href="drop.setBuilder"
				target="_blank"
				class="block w-full bg-white text-black border border-black/20 hover:border-black/80 uppercase font-bold text-center py-2 px-4 rounded-md hover:scale-105 shadow-lg transition-all"
				>Set Builder</a
			>

			<a
				v-if="baseSetUrl(drop)"
				:href="baseSetUrl(drop)"
				class="block w-full bg-white text-black border border-black/20 hover:border-black/80 uppercase font-bold text-center py-2 px-4 rounded-md hover:scale-105 shadow-lg transition-all"
				>Base Set</a
			>

			<a
				v-if="colorPopsUrl(drop)"
				:href="colorPopsUrl(drop)"
				class="block w-full bg-white text-black border border-black/20 hover:border-black/80 uppercase font-bold text-center py-2 px-4 rounded-md hover:scale-105 shadow-lg transition-all"
				>Color Pops</a
			>
		</div>
	</div>
</template>

<script setup lang="ts">
import { isAfter, format } from 'date-fns'
import { onMounted, onUnmounted, computed, ref, type PropType } from 'vue'

import { msleep } from '@/util/sleep'
import { Drop, UnrevealedCard, UnrevealedColorPop } from '@/types/Drop'

const props = defineProps({
	drop: {
		type: Object as PropType<Drop>,
	},
	show: {
		type: Number,
		default: 6,
	},
	delay: {
		type: Number,
		default: 0,
	},
	timer: {
		type: Number,
		default: 5000,
	},
})
const allRares = ref<Array<UnrevealedColorPop | UnrevealedCard>>([])
const counter = ref<number>(0)
let interval = null

onMounted(async () => {
	allRares.value = buildRares()
	await msleep(props.delay)

	if (allRares.value.length > props.show) {
		interval = window.setInterval(() => {
			counter.value++
		}, props.timer)
	}
})

onUnmounted(() => {
	if (interval !== null) {
		window.clearInterval(interval)
		interval = null
	}
})

function buildRares(): Array<UnrevealedColorPop | UnrevealedCard> {
	let ret = Array<UnrevealedColorPop | UnrevealedCard>()

	if (props.drop.unrevealedCards) {
		ret.push(...props.drop.unrevealedCards.cards)
	}

	if (props.drop.unrevealedColorPops) {
		ret.push(...props.drop.unrevealedColorPops.cards)
	}

	ret = ret.sort(() => Math.random() * 2 - 1)
	return ret
}

const showRares = computed<Array<UnrevealedCard | UnrevealedColorPop>>(
	(): Array<UnrevealedCard | UnrevealedColorPop> => {
		const show = props.show + 2
		const start = counter.value % allRares.value.length

		const ret = Array<UnrevealedCard | UnrevealedColorPop>(0)

		for (let i = start; ret.length < show; i++) {
			ret.push(allRares.value[i])
		}

		for (let i = 0; ret.length < show; i++) {
			ret.push(allRares.value[i])
		}

		return ret
	}
)

function baseSetUrl(drop): string | false {
	if (!isAfter(new Date(), new Date(drop.openable_at))) {
		return false
	}

	return `/store/hth-${drop.sport}-${drop.season}-base-set`.toLowerCase()
}

function colorPopsUrl(drop): string | false {
	if (!isAfter(new Date(), new Date(drop.openable_at))) {
		return false
	}

	return `/store/hth-${drop.sport}-${drop.season}-color-pops`.toLowerCase()
}
</script>
