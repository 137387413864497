<template>
	<article class="mb-4 overflow-hidden flex flex-col sm:flex-row relative max-w-screen-md border-t pt-4">
		<div class="flex pr-4 w-full">
			<div class="max-w-20 sm:w-full shrink-0 overflow-hidden">
				<img :src="$cdnify(props.item.asset.image, 312)" :alt="props.item.asset.name" class="rounded-xl" />
			</div>
			<div class="px-3">
				<p class="text-base">
					{{ props.item.asset.name }}
				</p>
				<p class="text-sm text-gray-500">
					{{ marketStore.byID[props.item.asset.storefront.id].name }}
				</p>
			</div>
		</div>
		<div class="p-2 px-4 w-full sm:w-60 bg-white rounded-lg mt-4 sm:mt-0">
			<label :for="`amountToSend-${props.item.asset_id}`" class="block text-base text-gray-500 whitespace-nowrap">
				Amount to Send
			</label>
			<div class="relative rounded-md shadow-sm flex items-center text-md border-gray-800">
				<button
					@click="subQuantity"
					class="absolute px-3 inset-y-0 left-1 bg-white text-gray-400 enabled:hover:text-gray-800 my-0.5 disabled:bg-slate-50 disabled:opacity-50"
					:disabled="props.item.quantity <= 1"
				>
					<i class="fas fa-minus"></i>
				</button>
				<input
					:id="`amountToSend-${props.item.asset_id}`"
					type="text"
					class="block w-full pl-10 pr-10 sm:text-md text-center border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50"
					placeholder="# Quantity"
					v-model="props.item.quantity"
				/>
				<button
					@click="addQuantity"
					class="absolute px-3 inset-y-0 right-1 bg-white text-gray-400 enabled:hover:text-gray-800 my-0.5 disabled:bg-slate-50 disabled:opacity-50"
					:disabled="props.item.quantity >= props.item.asset.balance.available"
				>
					<i class="fas fa-plus"></i>
				</button>
			</div>
			<div class="flex flex-wrap justify-between items-center sm:flex-col sm:items-start pt-2">
				<p v-if="!isValid" class="text-red-500 text-xs mt-2 w-full my-2">
					<span v-if="!isValidNumber">Please enter a valid number.</span>
					<span v-else-if="!isValidAvailable">Quantity cannot be more than owned.</span>
				</p>
				<p class="text-xs">{{ props.item.asset.balance.available }} Available</p>
				<button
					@click="() => emit('remove', props.item.asset.asset_identifier)"
					class="text-gray-500 hover:text-red-500 text-xs underline self-start"
				>
					Remove
				</button>
			</div>
		</div>
	</article>
</template>

<script lang="ts" setup>
import { useMarketStore } from '@/stores/MarketStore'
import { BasketItem } from '@/stores/BulkTransferStore'
import { computed, defineProps } from 'vue'

const marketStore = useMarketStore()

const props = defineProps<{
	item: BasketItem
}>()

const emit = defineEmits<{
	(e: 'remove', asset_id: string): void
}>()

const isValidAvailable = computed(() => {
	return Number(props.item.quantity) <= props.item.asset.balance.available
})

const isValidNumber = computed(() => {
	return !isNaN(props.item.quantity) && props.item.quantity > 0
})

const isValid = computed(() => {
	const validity = isValidAvailable.value && isValidNumber.value
	props.item.isQuantityValid = validity
	return validity
})

function addQuantity() {
	if (isNaN(props.item.quantity)) {
		props.item.quantity = 1
	}
	props.item.quantity = Number(props.item.quantity) + 1
}

function subQuantity() {
	if (isNaN(props.item.quantity)) {
		props.item.quantity = 1
	} else if (props.item.quantity <= 0) {
		props.item.quantity = 1
	} else {
		props.item.quantity = props.item.quantity - 1
	}
}
</script>
