import { dom } from '@fortawesome/fontawesome-svg-core'

import './classic-solid'
import './classic-regular'
import './classic-light'

import './sharp-solid'
import './sharp-light'

import './duotone-solid'

import './brands'

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()
