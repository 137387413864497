<template>
	<div class="max-w-screen-lg mx-auto w-full mt-12 mb-24 px-4">
		<div class="text-4xl xl:text-6xl font-poppins font-black mb-6">Home Team Heroes Drops</div>

		<template v-for="season in seasons">
			<div class="font-poppins font-black text-4xl mt-12 mb-2">{{ season }}</div>

			<div class="space-y-8">
				<HomeTeamHeroesDrop
					v-for="(drop, i) in seasonDrops(season)"
					:drop="drop"
					:delay="seasonDelay(season, 4000, i)"
					:timer="4000"
				/>
			</div>
		</template>
	</div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { isAfter } from 'date-fns'

import api from '@/util/api'
import HomeTeamHeroesDrop from '@/components/HomeTeamHeroesDrop.vue'
import { Drop } from '@/types/Drop'

const drops = ref<Drop[]>([])
const sports = ['Baseball', 'Football', 'Basketball']

onMounted(async () => {
	const res = (await api.loadDrops()).sort(sortDrops)
	drops.value = res
})

const seasons = computed<number[]>(() => {
	return (
		drops.value
			?.map((drop) => {
				return drop.season
			})
			.filter((drop, i, drops) => {
				return drops.indexOf(drop) === i
			}) ?? []
	)
})

function sortDrops(a: Drop, b: Drop): number {
	if (a.season > b.season) return -1
	if (a.season < b.season) return +1

	if (sports.indexOf(a.sport) < sports.indexOf(b.sport)) return -1
	if (sports.indexOf(a.sport) > sports.indexOf(b.sport)) return +1

	if (a.openable_at < b.openable_at) return -1
	if (a.openable_at > b.openable_at) return +1

	return 0
}

const seasonDrops = (season: number) => {
	if (drops.value.length == 0) {
		return []
	}

	const ret = drops.value.filter((drop) => drop.season === season)
	return ret
}

const seasonDelay = (season: number, timer: number, i: number) => {
	if (drops.value.length == 0) {
		return []
	}

	const ret = Math.round(
		(i * timer) / seasonDrops(season).filter((drop) => isAfter(new Date(), new Date(drop.openable_at))).length
	)
	return ret
}
</script>
