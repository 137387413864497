// @ts-nocheck
import axios from 'axios'
import Errors from '@/util/Errors'
import { Auction, AuctionImage, Bid } from '@/types/Auction'
import { Event } from '@/types/Event'
import { Taxonomy, Tag } from '@/types/Taxonomy'
import { DateTime, Duration } from 'ts-luxon'
import { AssetFilter, NewOffer, Storefront, StorefrontAssetResponse } from '@/types/Storefront'
import { Asset, Listing, makeAsset } from '@/types/Asset'
import { ExchangeRate } from '@/types/Currency'
import { MarketCurrency } from '@/stores/NewWalletStore'
import { AssetPurchaseSuccess } from '@/components/notices/storefront/notices'
import { Notice } from '@/types/Notice'
import { BatchDripClaimResponse, DripClaimResponse } from '@/types/Drip'
import Bugsnag from '@bugsnag/js'

const ENVIRONMENT = import.meta.env.VITE_ENV
const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT

function parseAuctionApiObject(auction: any): Auction {
	let a: Auction = {
		id: auction.id,
		blockchain_auction_id: auction.blockchain_auction_id,
		seller_token_id: auction.seller_token_id,
		title: auction.title,
		description: auction.description,
		md_description: auction.md_description,
		starting_bid: auction.starting_bid,
		buyer_premium_bps: auction.buyer_premium_bps,
		bid_increment: auction.bid_increment,
		auction_group: {
			id: auction.auction_group.id,
			name: auction.auction_group.name,
			slug: auction.auction_group.slug,
			image: auction.auction_group.image,
			start_at: DateTime.fromISO(auction.auction_group.start_at),
			end_at: DateTime.fromISO(auction.auction_group.end_at),
		},
		extended_bidding_seconds: Duration.fromObject({
			seconds: auction.extended_bidding_seconds,
		}),
		current_status: auction.current_status,
		bid_count: auction.bid_count,
		high_bid: auction.high_bid,
		high_bidder: auction.high_bidder,
		last_bid_at: auction.last_bid_at ? DateTime.fromISO(auction.last_bid_at) : null,
		end_at: DateTime.fromISO(auction.end_at),
		images: [] as AuctionImage[],
		bids: [] as Bid[],
		tags: [] as Tag[],

		claimed_at: auction.claimed_at ? DateTime.fromISO(auction.claimed_at) : null,
		claimed_by: auction.claimed_by || null,
		claim_recipient: auction.claim_recipient || null,
		claim_tx_hash: auction.claim_tx_hash || null,

		proceeds_claimed_at: auction.proceeds_claimed_at ? DateTime.fromISO(auction.proceeds_claimed_at) : null,
		proceeds_claimed_by: auction.proceeds_claimed_by || null,
		proceeds_claim_recipient: auction.proceeds_claim_recipient || null,
		proceeds_claim_tx_hash: auction.proceeds_claim_tx_hash || null,
	}

	auction.image.map((img: AuctionImage) => {
		a.images.push({
			uri: img.uri,
			display_order: img.display_order,
		})
	})

	if (auction.bid) {
		auction.bid.map((bid: Bid) => {
			a.bids.push(parseBidApiObject(bid))
		})
	}

	if (auction.tags) {
		auction.tags.map((tag: Tag) => {
			a.tags.push(parseTagApiObject(tag))
		})
	}

	return a
}

function parseBidApiObject(bid: Bid) {
	const b = {
		auction_id: bid.auction_id,
		blockchain_auction_id: bid.blockchain_auction_id,
		bid: bid.bid,
		bidder: bid.bidder,
		tx_hash: bid.tx_hash,
		bid_at: DateTime.fromISO(bid.bid_at),

		auction: bid.auction ? parseAuctionApiObject(bid.auction) : null,
	}

	return b
}

function parseTagApiObject(tag: Tag) {
	return {
		id: tag.id,
		name: tag.name,
		slug: tag.slug,
	} as Tag
}

function parseTaxonomy(data: any): Taxonomy {
	let t: Taxonomy = {
		id: data.id,
		name: data.name,
		slug: data.slug,
		tags: [] as Tag[],
	}

	data.tags.map((tag: any) => {
		t.tags.push(parseTagApiObject(tag))
	})

	return t
}

function parseEventApiObject(event: any): Event {
	let e: Event = {
		id: event.id,
		name: event.name,
		slug: event.slug,
		image: event.image,
		active: event.active,
		start_at: DateTime.fromISO(event.start_at),
		end_at: DateTime.fromISO(event.end_at),
	}
	return e
}

function apiUri(path: string) {
	return `${API_ENDPOINT}${path}`
}

async function getLoginUri(redirectTo: string): Promise<string> {
	const response = await axios({
		// withCredentials: true,
		url: apiUri('/api/login/redirect'),
		method: 'POST',
		data: {
			redirect_to: redirectTo,
		},
		headers: {
			Accept: 'application/json',
		},
	})

	if (response.status !== 200) {
		// bad news
		return 'nope'
	}

	return response.data.uri
}

async function authenticate(code: string, state: string): Promise<any> {
	const response = await axios({
		url: apiUri('/api/login/auth'),
		method: 'POST',
		data: {
			code,
			state,
		},
		headers: {
			Accept: 'application/json',
		},
	})

	if (response.status !== 200) {
		return 'nope'
	}

	return response.data
}

async function whoami(): Promise<any> {
	try {
		const response = await axios(apiUri('/api/whoami'))

		if (response.status != 200) {
			// bad
			return null
		}

		return response.data.data
	} catch (e) {
		// don't care
	}

	return false
}

async function withdraw(asset: string, amount: string, wallet: string): Promise<any> {
	const response = await axios({
		url: apiUri(`/api/account/withdraw/${asset}`),
		method: 'POST',
		params: {
			amount,
			wallet,
		},
	})

	if (response.status !== 200) {
		return 'nope'
	}

	return response.data
}

async function balance(): Promise<any> {
	const response = await axios({
		url: apiUri('/api/account/balance'),
		method: 'GET',
	})

	if (response.status !== 200) {
		return 'nope'
	}

	return response.data
}

async function loadStorefronts(): Promise<Storefront[]> {
	const response = await axios(apiUri('/api/storefronts'))

	if (200 != response.status) {
		return [] as Storefront[]
	}

	// sort ascending and place null at bottom
	response.data.data.sort(
		({ featured_storefront_sort_order: a }: Storefront, { featured_storefront_sort_order: b }: Storefront) =>
			(a === null) - (b === null) || +(a > b) || -(a < b)
	)

	return response.data.data
}

async function loadDrops(): Promise<Drop[]> {
	const resp = await axios(apiUri('/api/drops'))
	return resp.data.drops
}

async function loadFeaturedListings(): Promise<Asset[]> {
	const response = await axios(apiUri('/api/storefront/reward-room/featured-listings'))

	if (200 !== response.status) {
		return [] as Asset[]
	}

	return response.data.data
}

async function loadStorefront(slug: string): Promise<Storefront | null> {
	const response = await axios(apiUri(`/api/storefront/${slug}`))

	if (200 != response.status) {
		return null
	}

	return response.data.data
}

async function loadStorefrontMetadata(slug: string): Promise<StorefrontMetadata> {
	const response = await axios(apiUri(`/api/storefront/${slug}/metadata`))

	if (200 != response.status) {
		return {}
	}

	return response.data.data
}

async function loadStorefrontAssets(
	slug: string,
	pgNumber: number = 0,
	perPage: number = 100,
	filters: AssetFilter
): Promise<StorefrontAssetResponse[]> {
	const response = await axios({
		method: 'GET',
		url: apiUri(`/api/storefront/${slug}/assets`),
		params: { ...filters, pg: pgNumber, perPage },
	})

	if (200 != response.status) {
		return {
			assets: [],
			pagination: {},
		}
	}

	return {
		assets: response.data.data,
		pagination: response.data.meta,
	}
}

async function loadStorefrontActivity(slug: string, page: number = 0, perPage: number = 25, filters: AssetFilter) {
	const response = await axios({
		method: 'GET',
		url: apiUri(`/api/v4/storefront/${slug}/activity`),
		params: {
			page,
			perPage,
			...filters,
		},
	})

	return response.data
}

async function loadAssetActivity(slug: string, asset: string, page: number = 1, perPage: number = 25) {
	const response = await axios({
		method: 'GET',
		url: apiUri(`/api/v3/storefront/${slug}/asset/${asset}/activity`),
		params: {
			page,
			perPage,
		},
	})

	return response.data
}

async function loadStorefrontActivityAccount(page: number = 1, perPage: number = 25) {
	const response = await axios({
		method: 'GET',
		url: apiUri(`/api/v3/account/activity`),
		params: {
			page,
			perPage,
		},
	})

	return response.data
}

async function loadAsset(slug?: string, assetId?: number): Promise<any> {
	return await axios({
		url: apiUri(`/api/v2/storefront/${slug}/asset/${assetId}`),
		method: 'GET',
		headers: {
			Accept: 'application/json',
		},
	}).then((res) => {
		return makeAsset(res.data.data)
	})
}

async function loadUserAssets(
	page: number,
	perPage: number,
	filters: AssetFilter,
	collections: string[] = [],
	walletVersion: string = ''
): Promise<{
	data: Asset[]
	links: { first: string; last: string; prev: string | null; next: string | null }
	meta: {
		current_page: number
		from: number
		last_page: number
		path: string
		per_page: number
		to: number
		total: number
	}
	userOwnedStorefrontIds: string[]
}> {
	const assets = await axios({
		url: apiUri(`/api/v2/account/assets`),
		method: 'GET',
		headers: {
			Accept: 'application/json',
		},
		params: {
			page,
			perPage,
			...filters,
			collections,
			walletVersion,
		},
	})

	return assets.data
}

async function loadUserNotices(): Promise<Notice[]> {
	const notices = await axios({
		url: apiUri(`/api/account/notices`),
		method: 'GET',
		headers: {
			Accept: 'application/json',
		},
	})

	return (notices.data.data ?? []) as Notice[]
}

async function dismissUserNotice(noticeId: string): Promise<boolean> {
	const response = await axios({
		url: apiUri(`/api/account/notice/${noticeId}`),
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
		},
	})

	return response.data.success
}

async function loadUserAuctions(page: number = 1): Promise<{ data: Listing[]; meta: Object }> {
	const listings = await axios({
		url: apiUri(`/api/account/auctions`),
		method: 'GET',
		params: {
			page,
		},
		headers: {
			Accept: 'application/json',
		},
	})

	return listings.data
}

async function getExchangeRate(): Promise<ExchangeRate> {
	const { status, data } = await axios({
		url: apiUri(`/api/exchange`),
		method: 'GET',
		headers: { Accept: 'application/json' },
	})

	return data.data
}

async function usdVal(bpxVal: string, isDec: boolean = false): Promise<ExchangeRate> {
	const response = await axios({
		url: apiUri(`/api/exchange`),
		method: 'GET',
		params: {
			[isDec ? 'bpx_dec' : 'bpx']: bpxVal,
		},
		headers: { Accept: 'application/json' },
	})

	return response.data.data
}

async function bpxVal(usdVal: number | string): Promise<ExchangeRate> {
	const response = await axios({
		url: apiUri(`/api/exchange`),
		method: 'GET',
		params: {
			usd: usdVal,
		},
		headers: { Accept: 'application/json' },
	})

	return response.data.data
}

async function getCurrencyBalances(): Promise<MarketCurrency[]> {
	const response = await axios({
		url: apiUri(`/api/account/balance`),
		method: 'GET',
	})

	if (response.status !== 200) {
		return [] as MarketCurrency[]
	}

	return response.data.balance
}

async function createListing(listing: NewListing): Promise<Listing | null> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/storefront/${listing.storefront_id}/listing`),
			method: 'POST',
			data: {
				listing,
			},
		})

		return data.data
	} catch (e) {
		console.error('Failed to create asset listing: ', e.message)
		return null
	}
}

async function editListing(listingID: string, listing: NewListing): Promise<Listing | null> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/listing/${listingID}`),
			method: 'PUT',
			data: {
				listing,
			},
		})

		return data.data
	} catch (e) {
		console.error('Failed to create asset listing: ', e.message)
		return null
	}
}

async function deleteListing(listingID: string): Promise<boolean> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/listing/${listingID}`),
			method: 'DELETE',
		})

		return data.success
	} catch (e) {
		console.error('Failed to delete listing: ', e.message)
		return false
	}
}

async function createOrder(assetID: string, listingID: string): Promise<PaymentIntent | null> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/order`),
			method: 'POST',
			data: {
				asset_id: assetID,
				listing_id: listingID,
			},
		})

		return data
	} catch (e) {
		console.error('Failed to create order: ', e.message)
		return null
	}
}

async function placeBid(
	paymentMethods: string[],
	bpxBid: string,
	listingID: string
): Promise<{ success: boolean; high_bid: number; message: string; code?: number; is_winning: boolean }> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/listing/${listingID}/bid`),
			method: 'POST',
			data: {
				payment_methods: paymentMethods,
				bid: bpxBid,
			},
		})

		if (status == 200) {
			return data
		}

		return {
			success: false,
			message: status.data.message ?? 'Failed to place bid',
		}
	} catch (e) {
		return {
			success: false,
			message: e.response?.data?.message ?? 'Failed to place bid',
		}
	}
}

async function buyListing(
	paymentMethods: string[],
	listingID: string
): Promise<{ success: boolean; message: string; code?: number } | null> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/v3/listing/${listingID}/buy`),
			method: 'POST',
			data: {
				payment_methods: paymentMethods,
			},
		})

		if (status) {
			return data
		}

		return {
			success: false,
			message: 'Failed to purchase listing',
		}
	} catch (e) {
		console.error('Failed to purchase listing: ', e.message)
		return {
			success: false,
			message: 'Failed to purchase listing',
		}
	}
}

async function transferAssets(
	assets: { asset_id: string; quantity: number | bigint }[],
	recipient: string,
	pin: string | null,
	nonce?: string
): Promise<{ success: boolean; message: string; code: number | string | null; sid?: string | null }> {
	try {
		// Convert BigInt to strings
		const assetsWithConvertedQuantities = assets.map((asset) => ({
			...asset,
			quantity: typeof asset.quantity === 'bigint' ? asset.quantity.toString() : asset.quantity,
		}))

		const { status, data } = await axios({
			url: apiUri(`/api/account/transfer`),
			method: 'POST',
			validateStatus(status) {
				return status >= 200 && status < 500
			},
			data: {
				assets: assetsWithConvertedQuantities,
				recipient,
				code: pin,
				nonce,
			},
		})

		if (status === 403) {
			return {
				success: false,
				message: data.message,
				sid: data.sid,
				code: 403,
			}
		}

		if (status < 200 || status > 299) {
			return {
				success: false,
				message: `Failed to transfer Asset${assets.length != 1 ? 's' : ''}`,
			}
		}

		if (data.success) {
			return {
				success: true,
				message: `Asset${assets.length != 1 ? 's' : ''} transferred successfully`,
			}
		} else {
			return {
				success: false,
				message: data.message,
			}
		}
	} catch (e) {
		console.error(e.message)
		return {
			success: false,
			message: `Failed to transfer Asset${assets.length != 1 ? 's' : ''}`,
		}
	}
}

async function initiateStoreCreditPurchase(
	amount: number,
	redirect: string | null = null
): Promise<{ success: boolean; message?: string; redirect?: string }> {
	const response = await axios({
		url: apiUri('/api/account/miles/buy'),
		method: 'POST',
		data: {
			amount,
			redirect,
		},
	})

	return response.data
}

function getLogoutUrl(returnPath?: string): string {
	return apiUri('/api/logout') + (returnPath ? `?redirect=${returnPath}` : '')
}

async function getColorPopLeaderboardData(season: string, sport: string) {
	return await axios
		.get(`/color-pop-api/${sport}/${season}/players`)
		.then((res) => res.data) // the axios object
		.then((res) => res.data) // the response from laravel wraps everything in a data field
		.catch((error) => {
			console.log(error.message)
		})
}

async function getMilesPackages() {
	const response = await axios.get(apiUri('/api/miles'))

	if (response.status !== 200) {
		return []
	}

	return response.data.data
}
async function getUserFavoritedAssets() {
	const res = await axios({
		url: apiUri(`/api/account/favorites`),
		method: 'GET',
	})
	return res.data
}

async function createUserFavorite(assetId: string) {
	return await axios({
		url: apiUri(`/api/favorite/${assetId}`),
		method: 'POST',
	})
}

async function deleteUserFavorite(assetId: string) {
	return await axios({
		url: apiUri(`/api/favorite/${assetId}`),
		method: 'DELETE',
	})
}

async function loadDrip(assetId: string): Promise<Drip | null> {
	let result
	try {
		result = await axios.get(apiUri(`/api/v1/drip/${assetId}`))

		if (result.status == 404) {
			return null
		}
	} catch (e) {
		if (e.response && e.response.status == 404) {
			// no drip found for this asset
			return null
		}

		if (ENVIRONMENT !== 'production') {
			console.error(e)
		} else {
			Bugsnag.notify(e)
		}

		return null
	}

	return {
		asset_id: result.data.data.asset_id,
		maturity_date: DateTime.fromISO(result.data.data.maturity_date),
		start_date: DateTime.fromISO(result.data.data.start_date),
		daily_emission: result.data.data.daily_emission,
		remaining_emission: result.data.data.remaining_emission,
		total_emission: result.data.data.total_emission,
		withdrawn_value: result.data.data.withdrawn_value,
		current_value: result.data.data.current_value,
	} as Drip
}

async function claimDrip(assetId: string): Promise<DripClaimResponse> {
	let response

	try {
		response = await axios.post(apiUri(`/api/v1/drip/${assetId}/claim`))

		if (response.status == 201) {
			return {
				success: true,
			} as DripClaimResponse
		}

		return {
			success: false,
			message: response.data.message,
			code: response.data.code ?? 0,
		} as DripClaimResponse
	} catch (e) {
		return {
			success: false,
			message: response.data?.message ?? 'An unexpected error occurred.',
			code: response.data?.code ?? -1,
		} as DripClaimResponse
	}
}

async function loadAccountDrips(): Promise<AccountDripIndexResponse> {
	try {
		const response = await axios.get(apiUri('/api/v1/account/drips'))

		return response.data.data as AccountDripIndexResponse
	} catch (e) {
		return {
			drips: [],
		} as AccountDripIndexResponse
	}
}

async function claimDrips(dripIds: string[]): Promise<BatchDripClaimResponse> {
	let result
	try {
		result = await axios.post(apiUri(`/api/v2/drip/claim`), {
			drip_id: dripIds,
		})

		if (result.status == 200) {
			return {
				success: result.data.success,
				batch_id: result.data.batch_id,
			} as BatchDripClaimResponse
		}
	} catch (e) {
		if (ENVIRONMENT !== 'production') {
			console.error(e)
		} else {
			Bugsnag.notify(e)
		}

		return {
			success: false,
		} as BatchDripClaimResponse
	}
}

async function batchStatus(batchId: string): Promise<null | BatchClaimStatusResponse> {
	let result
	try {
		result = await axios.get(apiUri(`/api/v2/drip/claim/${batchId}`))

		if (result.status == 200) {
			return result.data.data as BatchClaimStatusResponse
		}
	} catch (e) {
		if (ENVIRONMENT !== 'production') {
			console.error(e)
		} else {
			Bugsnag.notify(e)
		}

		return null
	}
}

async function loadOffers(storeSlug: string): Promise<Offer[]> {
	const req = {
		url: apiUri(`/api/storefront/${storeSlug}/offers`),
		method: 'GET',
	}

	try {
		const response = await axios(req)

		return response.data.data.sort((a: Offer, b: Offer) => {
			return b.offer_bpx_per_token - a.offer_bpx_per_token
		})
	} catch (e) {
		console.error(e)
		return []
	}
}

async function createOffer(storeSlug: string, offer: NewOffer): Promise<{ offer: Offer; success: boolean }> {
	const response = await axios({
		url: apiUri(`/api/storefront/${storeSlug}/offer`),
		method: 'POST',
		data: {
			offer,
		},
	})

	return response.data
}

async function cancelOffer(storeSlug: string, offerId: string): Promise<{ success: boolean; message: string }> {
	const response = await axios({
		url: apiUri(`/api/storefront/${storeSlug}/offer/${offerId}/cancel`),
		method: 'PUT',
	})

	return response.data
}

async function getUserOffers(): Promise<Offer[]> {
	const req = {
		url: apiUri(`/api/account/offers`),
		method: 'GET',
		headers: {
			Accept: 'application/json',
		},
	}

	const response = await axios(req)

	if (response.status !== 200) {
		return []
	}

	return response.data.data
}

async function acceptOffer(storeSlug: string, offer: Offer): Promise<{ success: boolean; message: string }> {
	const { token_count, asset_identifier, storefront_id } = offer
	const response = await axios({
		url: apiUri(`/api/storefront/${storeSlug}/offer/${offer.id}`),
		method: 'PUT',
		data: {
			storefront_id,
			token_count,
			asset_identifier,
		},
	})

	return response.data
}

async function confirm2fa(code, sid?: string) {
	const response = await axios({
		url: apiUri('/api/confirm-2fa'),
		method: 'POST',
		data: {
			code,
			sid,
		},
	})

	return response.data
}

async function getLatestWalletVersion(): Promise<{
	success: boolean
	message: string
	data: {
		walletVersion: string
	}
}> {
	const response = await axios({
		url: apiUri('/api/account/current'),
		method: 'GET',
	})

	return response.data
}

export default {
	acceptOffer,
	authenticate,
	balance,
	batchStatus,
	bpxVal,
	buyListing,
	cancelOffer,
	claimDrip,
	claimDrips,
	confirm2fa,
	createListing,
	createOffer,
	createOrder,
	createUserFavorite,
	deleteListing,
	deleteUserFavorite,
	dismissUserNotice,
	editListing,
	getColorPopLeaderboardData,
	getCurrencyBalances,
	getExchangeRate,
	getLatestWalletVersion,
	getLoginUri,
	getLogoutUrl,
	getMilesPackages,
	getUserFavoritedAssets,
	getUserOffers,
	initiateStoreCreditPurchase,
	loadAccountDrips,
	loadAsset,
	loadAssetActivity,
	loadDrip,
	loadDrops,
	loadFeaturedListings,
	loadOffers,
	loadStorefront,
	loadStorefrontActivity,
	loadStorefrontActivityAccount,
	loadStorefrontAssets,
	loadStorefrontMetadata,
	loadStorefronts,
	loadUserAssets,
	loadUserAuctions,
	loadUserNotices,
	placeBid,
	transferAssets,
	usdVal,
	whoami,
	withdraw,
}
