export default function sleep(seconds: number) {
	return new Promise((res) => {
		setTimeout(res, seconds * 1000)
	})
}

export function msleep(ms: number) {
	return new Promise((res) => {
		setTimeout(res, ms)
	})
}

export function waitLoad<T>(exec: () => Promise<T>, afterWaitCallback: () => {}, timeout: number = 0.25): Promise<T> {
	const shh = sleep(timeout)
	return exec().then(async (result) => {
		await shh
		afterWaitCallback()
		return result
	})
}
