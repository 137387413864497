<template>
	<loading-storefront v-if="state.loading" />
	<section v-else class="bpmp-layout">
		<header class="bpmp-layout__header">
			<storefront-header :storefront="storeStore.state.storefront" />

			<div class="flex items-end mx-4 mt-4 overflow-x-auto">
				<div class="border-b-2 border-gray-300 flex space-x-4 grow">
					<router-link
						:to="{ name: 'storefront', params: { slug: storeStore.state.storefront.slug } }"
						class="px-4 text-lg font-medium text-gray-500"
						exact-active-class="pb-1 border-b-2 border-gray-500"
					>
						Items
					</router-link>
					<router-link
						:to="{ name: 'storefront:activity' }"
						class="px-4 text-lg font-medium text-gray-500"
						exact-active-class="pb-1 border-b-2 border-gray-500"
					>
						Activity
					</router-link>
					<router-link
						v-if="storeStore.hasFeature(Flags.FEATURE_OFFERS) && flagStore.checkFlag(Flags.OFFERS_ACCEPT)"
						:to="{ name: 'storefront:offers' }"
						class="px-4 text-lg font-medium text-gray-500"
						exact-active-class="pb-1 border-b-2 border-gray-500"
					>
						Offers
					</router-link>
					<router-link
						v-if="isColorPop"
						:to="{ name: 'storefront:leaderboard' }"
						class="px-4 text-lg font-medium text-gray-500"
						exact-active-class="pb-1 border-b-2 border-gray-500"
					>
						Leaderboard
					</router-link>
				</div>
				<button
					v-if="storeStore.hasFeature(Flags.FEATURE_OFFERS) && flagStore.checkFlag(Flags.OFFERS_CREATE)"
					@click="state.isCreateOfferModalOpen = true"
					class="btn-primary-lg ml-4 shrink-0"
				>
					Make an Offer
				</button>
			</div>
		</header>
		<section class="bpmp-layout__content">
			<router-view :key="routeKey()" />
		</section>
		<create-offer-modal
			v-if="state.isCreateOfferModalOpen"
			@close="state.isCreateOfferModalOpen = false"
			@complete="handleCompletedOffer"
		/>
	</section>
</template>
<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, PropType, reactive } from 'vue'
import Storefront from '@/components/Storefront.vue'
import StorefrontHeader from '@/components/StorefrontHeader.vue'
import LoadingStorefront from '@/components/LoadingStorefront.vue'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { useRoute, useRouter } from 'vue-router'
import sleep from '@/util/sleep'
import { useEchoChannelStore } from '@/stores/EchoChannelStore'
import { Flags } from '@/types/Flags'
import { useFlagStore } from '@/stores/FlagStore'
import CreateOfferModal from '@/components/modals/CreateOfferModal.vue'

const state = reactive({
	loading: true,
	isCreateOfferModalOpen: false,
})

const echoChannelStore = useEchoChannelStore()

const route = useRoute()
const router = useRouter()
const storeStore = useStorefrontStore()
const flagStore = useFlagStore()
const isColorPop = computed(() => {
	const slug = route.params['slug'] as string
	if (!slug) {
		return false
	} else {
		return slug.toLowerCase().includes('color-pops')
	}
})

onMounted(async () => {
	if (storeStore.state.storefront?.slug !== route.params.slug) {
		state.loading = true
		const wait = sleep(0.75)
		/* @ts-ignore */
		await storeStore.loadStore(route.params.slug)
		wait.then(() => {
			state.loading = false
			echoChannelStore.activateChannel(
				`storefront.${storeStore.state.storefront.id}`,
				'ListingCreated',
				function (bid) {
					console.log(bid)
				}
			)
		})
	} else {
		state.loading = false
	}
})

onUnmounted(() => {
	echoChannelStore.deactivateChannel(`storefront.${storeStore.state.storefront.id}`)
})

function handleCompletedOffer() {
	state.isCreateOfferModalOpen = false
	router.push({ name: 'storefront:offers' })
}

function routeKey() {
	return route.name === 'storefront:offers' ? `${route.fullPath}${Math.random()}` : route.fullPath
}
</script>

<style>
@tailwind components;
@layer components {
	.bpmp-layout {
	}

	.bpmp-layout__content {
		@apply /* flex
      flex-row
      flex-wrap
      items-start */
      w-full;
	}

	.bpmp-layout__header {
		@apply flex-shrink-0
      w-full;
		/* background-color: red; */
	}

	.bpmp-layout__aside {
		@apply flex
      flex-shrink
      p-4
      sm:w-[20rem];
		background-color: blue;
	}

	.bpmp-layout__main {
		@apply flex-grow p-4;
		background-color: yellow;
	}
}
</style>
